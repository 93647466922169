import { render, staticRenderFns } from "./subIndex.vue?vue&type=template&id=516e11d4&scoped=true"
import script from "./subIndex.vue?vue&type=script&lang=js"
export * from "./subIndex.vue?vue&type=script&lang=js"
import style0 from "./subIndex.vue?vue&type=style&index=0&id=516e11d4&prod&scoped=true&lang=scss"
import style1 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "516e11d4",
  null
  
)

export default component.exports