<template>
  <div v-loading="loading" :class="[`theme-${site.themeDrak||'block'}`]">
    <NavBar
      :content="site.NavBar.props.content"
      v-if="site.NavBar.props.content"
    />
    <router-view></router-view>
    <FooterBar
      :content="site.FooterBar.props.content"
      v-if="site.NavBar.props.content"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      siteJson:{

      },
      site: {
        NavBar: { props: {} },
        FooterBar: { props: {} },
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      let host = this.$extractDomain(window.location.hostname);

      if (host == "localhost") host = sessionStorage.getItem("SiteEntrance");
      let commonJson = await import(`./site-json/${host}/common.json`);
      let siteJson = await import(`./site-json/${host}/site.json`);
      commonJson = await this.$replacePlaceholders(commonJson.default);
      let themeJson = {};
      // if (Number(result.data.type) === 1) {
      themeJson = require("./theme/voiceToText.js");
      // } else {
      //   themeJson = require("./theme/default.js");
      // }
      // const theme =
      //   themeJson.theme[`${result.data.site.theme || "default"}Theme`];
      const theme = themeJson.theme[`defaultTheme`];
      if (theme) {
        var root = document.querySelector(":root");
        Object.keys(theme).forEach((key) => {
          root.style.setProperty(key, theme[key]);
        });
      }
      sessionStorage.setItem("DefaultInfo", JSON.stringify(commonJson));
      this.site = commonJson;
      this.siteJson = siteJson;
      sessionStorage.setItem("funType", siteJson.type);
      let html = document.getElementsByTagName("html")[0];
      let width = window.innerWidth < 980 ? window.innerWidth : 1200;
      /* 640 100  320 50 */
      let fontSize = (width / 750) * 100;
      if (window.innerWidth > 980) {
        html.style.fontSize = 14 + "px";
      } else {
        html.style.fontSize = fontSize + "px";
      }
      this.loading = false;
      window.innerObj = {
        innerWidth: window.innerWidth,
      };
      window.onresize = function () {
        let html = document.getElementsByTagName("html")[0];
        let width = window.innerWidth < 980 ? window.innerWidth : 980;
        window.innerObj.innerWidth = window.innerWidth;
        /* 640 100  320 50 */
        let fontSize = (width / 750) * 100;
        if (window.innerWidth > 980) {
          html.style.fontSize = 14 + "px";
        } else {
          html.style.fontSize = fontSize + "px";
        }
      };
    },
  },
};
</script>
<style lang="scss">
.theme-block{
  // background: #000;

:root {
  /* 主题背景颜色 */
  --theme-primary: linear-gradient(315deg, #68c1ff 0%, #1681fd 100%);
  /* 默认黑 */
  --theme-primary-black: #fff;
}
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #13c16b !important;
}
@import "./scss/index.scss";

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.maks {
  height: 100vh;
}
input:focus {
  outline: none;
}
:root {
  /* 主题背景颜色 */
  --theme-primary: linear-gradient(315deg, #68c1ff 0%, #1681fd 100%);
  /* 默认黑 */
  --theme-primary-black: #000000;
  /* 默认白 */
  --theme-primary-white: #ffffff;
  /* 主题亮色 */
  --theme-primary-suffix: #1681fd;
  /* 价格 tag */
  --theme-primary-price-tag: #3790f7;
  --theme-primary-price-tag-bg: #e9edf8;
  /* 标签 */
  --theme-label: linear-gradient(115deg, #ff7373 0%, #fd9a5e 100%);
  /* tiny 类 */
  --theme-primary-tiny: #7c7c7c;
  /* footer 背景色 */
  --theme-primary-footer-bg: #1681fd;

  /* 输入框聚焦 */
  --theme-focus: #419eff;
  /* 输入框错误 */
  --theme-focus-err: #ea3042;

  /* banner 容器 */
  // --theme-primary-banner-title: #000000;
  // --theme-primary-banner-des1: #000029;
  /* explain 容器 */
  // --theme-primary-explain-title: #000000;
  // --theme-primary-explain-des: #000000;
  // --theme-primary-explain-des1: #000029;
  /* whyChoose 容器 */
  // --theme-primary-whyChoose-title: #ffffff;
  // --theme-primary-whyChoose-des: #ffffff;
  // --theme-primary-whyChoose-explain: #000000;
  // --theme-primary-whyChoose-exclusive: #ffffff;
  /* price 容器 */
  // --theme-primary-price-title: #000000;
  // --theme-primary-price-title-suffix: #ffffff;
  // --theme-primary-price-des: #000029;
  // --theme-primary-price-explain: #000000;
  // --theme-primary-price-exclusive: #ffffff;
  // --theme-primary-price-tiny: #7c7c7c;
  // --theme-primary-price-tag: #76c225;
  /* question 容器 */
  // --theme-primary-question-title: #000000;
  // --theme-primary-question-des: #000000;
  // --theme-primary-question-tiny: #7c7c7c;
  /* footer 容器 */
  // --theme-primary-footer-title: #ffffff;
  // --theme-primary-footer-des: #ffffff;
  // --theme-primary-footer-a: #ffffff;
  // --theme-primary-footer-tiny: #7c7c7c;
  // --theme-primary-footer-bg: #329678;
  /* 通用类 */
  // --theme-font-normal: #000000;
  // --theme-font-normal-bg: #ffffff;
  // --theme-font-normal-suffix: #ffffff;
}
</style>
