export function $extractDomain(url) {
 var hostname;
 if (url.includes("localhost")) {
  return 'elaihub'
 }
 // 移除协议（如 http://, https://）
 if (url.indexOf("//") > -1) {
  hostname = url.split('/')[2];
 } else {
  hostname = url.split('/')[0];
 }

 // 移除端口号
 hostname = hostname.split(':')[0];

 // 移除查询字符串
 hostname = hostname.split('?')[0];

 // 找到"."字符并从最后一个点切割
 var parts = hostname.split('.').reverse();

 if (parts != null && parts.length > 1) {
  // country code TLD (ccTLD) (如: .uk, .es 等)
  if (parts[1].length == 2 && parts.length > 2) {
   return parts[2];
  } else {
   return parts[1];
  }
 }
 return url;
}

export function $payApiHost() {
 const host = window.location.host.replace(/^www\./, '');  // Remove 'www.' from the beginning of the host
 if (host.includes('localhost')) {
  return `https://api.${$extractDomain(host)}.com`
 }
 let newHost = `https://api.${host}`;
 // console.log(newHost, window.location.host, "newHost>>>")
 return newHost;
 // return 'https://api.surfcipher.com'
 // return 'http://192.168.124.7:8889'
}
export function $websiteApiHost() {
 const host = window.location.host.replace(/^www\./, '');  // Remove 'www.' from the beginning of the host
 if (host.includes('localhost')) {
  return 'https://api-website.boxwizz.com'
 }
 let newHost = `https://api-website.${host}`;
 // console.log(newHost, window.location.host, "newHost>>>")
 return newHost;
 // return 'https://api-website.surfcipher.com'
// return 'http://192.168.124.7:8889'
}